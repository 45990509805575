import React from "react"
import Layout from "../components/layout"
import Download from "../components/Download"
import PageHeader from "../components/PageHeader"
import SEO from "../components/seo"

class DownloadPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Download" />
        <PageHeader title="Download" />
        <Download />
      </Layout>
    )
  }
}

export default DownloadPage
